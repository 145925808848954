var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "result-recommend-container" },
    [
      _vm.type == 0
        ? _c("div", { staticClass: "result-wrap" }, [
            _vm._m(0),
            _c("div", { staticClass: "opr-btns" }, [
              _c(
                "span",
                {
                  staticClass: "btn-new recommend-btn",
                  on: { click: _vm.handleRecommendDialog },
                },
                [_vm._v("立即推荐候选人")]
              ),
            ]),
          ])
        : _c("div", { staticClass: "result-wrap" }, [
            _vm._m(1),
            _c("div", { staticClass: "opr-btns" }, [
              _c(
                "span",
                {
                  staticClass: "btn-new look-btn",
                  on: {
                    click: function ($event) {
                      _vm.gotoViewDetail(_vm.getQueryString("candidateId"))
                    },
                  },
                },
                [_vm._v("查看")]
              ),
              _c(
                "span",
                { staticClass: "btn-new back-btn", on: { click: _vm.goBack } },
                [_vm._v("返回")]
              ),
            ]),
          ]),
      _vm.listData.length > 0 && _vm.isShow
        ? _c("div", { staticClass: "recommend-list-wrap" }, [
            _vm.type == 1
              ? _c("div", { staticClass: "recommend-list-title" }, [
                  _vm._v("更多相似候选人，查看有惊喜～"),
                ])
              : _c("div", { staticClass: "recommend-list-title" }, [
                  _vm._v("这些候选人都很适合这个职位哦，快看一看～"),
                ]),
            _c(
              "ul",
              { staticClass: "recommend-list" },
              _vm._l(_vm.listData, function (item) {
                return _c(
                  "li",
                  { key: item.candidateId, staticClass: "recommend-item" },
                  [
                    _c(
                      "p",
                      { staticClass: "base-info" },
                      [
                        _c("span", { staticClass: "info-name" }, [
                          _vm._v(_vm._s(item.candidateName)),
                        ]),
                        _c("span", { staticClass: "info-title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        !item.isTracking
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAddTrackingList(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _vm._v(
                                  "\n                         加入TrackingList\n                     "
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.isTracking
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleViewTrackingList(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-check" }),
                                _vm._v(
                                  "\n                         查看TrackingList\n                     "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "list-info" }, [
                      item.city
                        ? _c("span", { staticClass: "info-item" }, [
                            _vm._v(_vm._s(item.city)),
                          ])
                        : _vm._e(),
                      item.company
                        ? _c("span", { staticClass: "info-item" }, [
                            _vm._v(_vm._s(item.company)),
                          ])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "list-info" }, [
                      item.school
                        ? _c("span", { staticClass: "info-item" }, [
                            _vm._v(_vm._s(item.school)),
                          ])
                        : _vm._e(),
                      item.degree
                        ? _c("span", { staticClass: "info-item" }, [
                            _vm._v(_vm._s(item.degree)),
                          ])
                        : _vm._e(),
                      item.yearOfExperience
                        ? _c("span", { staticClass: "info-item" }, [
                            _vm._v(_vm._s(item.yearOfExperience) + "年"),
                          ])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "time-info" }, [
                      _vm._v(
                        "\n                     最近更新：" +
                          _vm._s(_vm._f("momentFormatDate")(item.updated)) +
                          "\n                 "
                      ),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "btn-new view-btn",
                        on: {
                          click: function ($event) {
                            return _vm.gotoDetail(item.candidateId)
                          },
                        },
                      },
                      [_vm._v("立即看看")]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 1 } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-text" }, [
      _c("img", {
        attrs: {
          src: require("@src/assets/images/candidateDetail/success.png"),
          alt: "",
        },
      }),
      _c("p", { staticClass: "success-text" }, [_vm._v("抢单成功!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "result-text" }, [
      _c("img", {
        attrs: {
          src: require("@src/assets/images/candidateDetail/success.png"),
          alt: "",
        },
      }),
      _c("p", { staticClass: "success-text" }, [_vm._v("推荐成功!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
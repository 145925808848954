<template>
    <div class="result-recommend-container">
         <div class="result-wrap" v-if="type==0">
             <div class="result-text">
                <img src="@src/assets/images/candidateDetail/success.png" alt="">
                <p class="success-text">抢单成功!</p>
            </div>
             <div class="opr-btns">
                <span class="btn-new recommend-btn" @click="handleRecommendDialog">立即推荐候选人</span>
             </div>
         </div>
         <div class="result-wrap" v-else>
             <div class="result-text">
                <img src="@src/assets/images/candidateDetail/success.png" alt="">
                <p class="success-text">推荐成功!</p>
             </div>
             <div class="opr-btns">
                <span class="btn-new look-btn" @click="gotoViewDetail(getQueryString('candidateId'))">查看</span>
                <span class="btn-new back-btn" @click="goBack">返回</span>
             </div>
             <!-- <div class="qrcode-wrap">
                 <span class="icon-new icon-qrcode"></span>
                 <p>扫码分享</p>
                 <div class="qrcode-img" v-if="qrcodeUrl">
                     <img :src="qrcodeUrl" alt="">
                 </div>
             </div> -->
         </div>
         <div class="recommend-list-wrap" v-if="listData.length>0 && isShow">
             <div class="recommend-list-title" v-if="type==1">更多相似候选人，查看有惊喜～</div>
             <div class="recommend-list-title" v-else>这些候选人都很适合这个职位哦，快看一看～</div>
             <ul class="recommend-list">
                 <li class="recommend-item" v-for="item in listData" :key="item.candidateId">
                     <p class="base-info">
                         <span class="info-name">{{item.candidateName}}</span>
                         <span class="info-title">{{item.title}}</span>
                         <el-button
                             v-if="!item.isTracking"
                             type="primary"
                             @click="handleAddTrackingList(item)"
                             round
                             plain>
                             <i class="el-icon-plus"></i>
                             加入TrackingList
                         </el-button>
                         <el-button
                             v-if="item.isTracking"
                             type="primary"
                             @click="handleViewTrackingList(item)"
                             round
                             plain>
                             <i class="el-icon-check"></i>
                             查看TrackingList
                         </el-button>
                     </p>
                     <p class="list-info">
                         <span class="info-item" v-if="item.city">{{item.city}}</span>
                         <span class="info-item" v-if="item.company">{{item.company}}</span>
                     </p>
                     <p class="list-info">
                        <span class="info-item" v-if="item.school">{{item.school}}</span>
                        <span class="info-item" v-if="item.degree">{{item.degree}}</span>
                        <span class="info-item" v-if="item.yearOfExperience">{{item.yearOfExperience}}年</span>
                     </p>
                     <p class="time-info">
                         最近更新：{{item.updated | momentFormatDate}}
                     </p>
                     <span class="btn-new view-btn" @click="gotoDetail(item.candidateId)">立即看看</span>
                 </li>

             </ul>
         </div>
        <recommend-dialog ref="recommendDialog" :type="1" ></recommend-dialog>

    </div>
</template>
<script>
import { intelligent_recommend as intelligent_recommend_url } from '#/js/config/api.json';
import moment from 'moment';
import RecommendDialog from '@src/component/common/dialog/recommendDialog.vue';
import qs from 'qs';
import {_momentFormatDate} from "#/js/util/global";
import AIRecommendService from "#/js/service/AIRecommendService";
import talentPoolService from '@src/js/service/talentPoolService.js';
export default {
    name:'result-recommend',
    data() {
        return {
            curRecommendJobType: '', // 当前推荐的职位类型：0为A2A职位，1为HR直招职位
            listData:[],
            type: -1,//0为职位，1为候选人
            qrcodeUrl: null,
            candidateId:null,
            jobId:null,
            recommendationId:null,
            code: null,
            recommendJobId:null,
            recommendReason:'',
            isShow: false,
            jobName: null
        }
    },
    components:{
        RecommendDialog
    },
    created() {
        let url = window.location.href;
        let p = url.split('&')[2] ? url.split('&')[2] : url.split('&')[1];
        console.log(p)
        let routeParams = new URLSearchParams(p);
        this.curRecommendJobType = routeParams.get('curRecommendJobType');
        // console.log(this.curRecommendJobType)
    },
    mounted() {
        this.getListData();
        if(this.type !== 0) {
            _tracker.track('recommendFromPage', JSON.stringify({
                fromPage: this.$route.query.fromPage && this.$route.query.fromPage !== 'undefined' ? this.$route.query.fromPage : 'JobDetail',
                isFirmResume: this.$route.query.isFirm,
                recommendationId: localStorage.getItem('recommendationId')
            }))
        }
    },
    computed: {
        marketJobType() {
            if(this.$route.query.marketJobType == 'undefined') {
                return null;
            } else {
                return this.$route.query.marketJobType;
            }
        },
    },
    methods: {
        getListData(){
            //候选人推荐成功
            let id, name, url;

            if(this.getQueryString('jobId')){
                id = this.getQueryString('jobId');
                name = _getQueryString('jobName');
                url = intelligent_recommend_url.job_recommend.replace(/%p/g,id);
                this.jobId = id;
                this.jobName = name;
                this.type = 0;
                document.title = document.title.replace(/推荐结果/, '抢单成功');
            }else{
                id = this.getQueryString('candidateId');
                this.candidateId = id;
                url = intelligent_recommend_url.recommend_recommend.replace(/%p/g,id);
                this.type = 1;
                //this.qrcodeUrl = `/Sharing/QRCode?url=`+encodeURIComponent(`${location.origin}/Sharing/Recommendation?code=${id}`);
                this.recommendJobId = this.getQueryString('recommendJobId');
                this.recommendReason = sessionStorage.getItem('recommendReason');
                this.recommendationId = sessionStorage.getItem('recommendationId');
                this.code =  sessionStorage.getItem('recommendShareCode');
                this.qrcodeUrl = `${_host.portal}/Sharing/QRCode?url=` + encodeURIComponent(`${_host.h5_page}/recommendation.html?code=${this.code}`);
                // setTimeout(() => {
                //     this.getUrl(id,this.recommendJobId);
                // }, 200);
            }
            if(!this.$store.state.user.userInfo.isCFUser){
                return false;
            } else {
                this.isShow = true;
            }

            _request({
                method: 'GET',
                url: url
            }).then(res=> {
                // handle success
                this.listData = res;
                let candidateIds = [];
                this.listData.forEach((candidate) => {
                    candidateIds.push(candidate.candidateId);
                });
                _tracker.track('resultRecommend',JSON.stringify({
                    from: this.type === 0 ? 'jobResult' : 'recommendResult',
                    list_count: this.listData.length,
                    origin_jobId: this.jobId,
                    origin_candidateId: this.candidateId,
                    candidateIds: candidateIds
                }))
            })
        },
        getQueryString(name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            if(window.location.hash.indexOf("?") < 0){
                    return null;
            }
            let r = window.location.hash.split("?")[1].match(reg); 
            if (r != null)
                if(name == 'jobName') {
                    return 
                } else {
                    return decodeURIComponent(r[2]);
                }
            return null;
        },
        gotoDetail(id){
            let eventName = 'jobResultRecommendView';//抢单后
            let from = 'jobResult';
            if(this.type == 1){
                eventName = 'recommendResultRecommendView';//推荐后
                from = 'recommendResult';
            }
            _tracker.track(eventName,JSON.stringify({
                candidateId: id,
                from: from,
                origin_jobId: this.jobId,
                origin_candidateId: this.candidateId
            }));
            window.open(`/Headhunting/MyCompany.html#/candidateDetail/${id}`);
            // window.open(`/#/candidateDetail/${id}`);
        },
        gotoViewDetail(id){
            window.open(`/Headhunting/MyCompany.html#/candidateDetail/${id}/recommendation/${this.recommendationId}`);
            // window.open(`/#/candidateDetail/${id}/recommendation/${this.recommendationId}`);
        },
        goBack(){
            window.history.go(-1);
        },
        formatDate(date){
            return _momentFormatDate(date);
        },
        handleRecommendDialog(){
            let eventName = 'jobResultRecommendView_recommend';//抢单后
            let from = 'jobResult';
            _tracker.track(eventName,JSON.stringify({
                from: from,
                origin_jobId: this.jobId,
                origin_candidateId: this.candidateId
            }));
            this.$refs.recommendDialog.show(this.jobId, this.jobName, this.curRecommendJobType, this.marketJobType);
        },
        getUrl(candidateId,jobId){
            /* let params = location.hash.split('?')[1];
            let url = intelligent_recommend_url.recommend_url.replace(/%p/g,jobId)+'?'+params;
            let token = this.$store.state.verificationToken.verificationToken;
            console.log(token)
            let data = {
                candidateId: candidateId,
                RecommendReason: this.recommendReason,
                shouldSendNotification: true,
                __RequestVerificationToken:token
            };
            let options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: qs.stringify(data),
                url,
            }
            axios(options)
                .then( (response)=> {
                    // handle success
                    let data = response.data;
                    if(data.succeeded){
                        this.longUrl = data.data.longUrl;
                        this.recommendationId = data.data.recommendationId;
                        this.qrcodeUrl = `/Sharing/QRCode?url=${this.longUrl}`;
                    }else{
                        let returnUrl = this.getQueryString('returnUrl');
                    }
                })
                .catch( (error) =>{
                    // handle error
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                }); */
                // this.recommendationId = localStorage.getItem('recommendationId');
                // this.longUrl =  localStorage.getItem('longUrl');
                // this.qrcodeUrl = `${_host.portal}/Sharing/QRCode?url=${this.longUrl}`;
        },
        handleAddTrackingList (item){
            let fromName = 'jobResult';
            if(this.type === 1){
                fromName = 'recommendResult';
            }
            talentPoolService.addToTrackingList({
                sourceFrom: this.type === 1 ? 13 : 12,
                resumeIds: item.resumeId
            }).then(res => {
                if(res.successCount === 1){
                    item.isTracking = true;
                    _tracker.track('addTrackingList',JSON.stringify({
                        candidateId: item.candidateId,
                        resumeId: item.resumeId,
                        from: fromName
                    }));
                    shortTips('候选人已加入Tracking List！');
                } else {
                    shortTips('候选人加入Tracking List失败！');
                }
            }).catch(err => {
                shortTips('候选人加入Tracking List失败！');
            })
        },
        handleViewTrackingList (item){
            if(localStorage){
                localStorage.setItem('trackingList_search_name', item.candidateName);
                localStorage.setItem('trackingList_search_company', item.company);
                localStorage.setItem('trackingList_search_title', item.title);
                localStorage.setItem('trackingList_search_mobile', item.mobile);
            }
            return window.open(`/Headhunting/#/trackingList`);
        }
    },
}
</script>
<style lang="scss">
    .result-recommend-container{
        background-color: #fff;
        height: 100%;
        .recommend-list-wrap{
            .recommend-list-title{
                font-size: 18px;
                color: #444;
                margin-bottom: 20px;
                border-left: 8px solid #38BC9D;
                padding-left: 12px;
                line-height: 1;
                font-weight: bold;
            }
            .recommend-list{
                display: flex;
                flex-wrap: wrap;
                .recommend-item{
                    position: relative;
                    width: 386px;
                    height: 175px;
                    border: 1px solid #ddd;
                    padding: 20px;
                    font-size: 14px;
                    color: #666;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    border-radius: 4px;
                    background: #fff;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    &:hover{
                        box-shadow: 0 0 12px rgba(0,0,0,.2);
                        transform: translate3d(0,-6px,0);
                        transition: all .6s;
                    }
                    .info-name{
                        color: #444;
                        font-size: 16px;
                        margin-right: 12px;
                        font-weight: bold;
                    }

                    .info-title{
                        display: inline-block;
                        max-width: 70px;
                        height: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: bottom;
                    }
                    p{
                        margin-bottom: 8px;
                    }
                    .view-btn{
                        position: absolute;
                        display: block;
                        width: 100px;
                        height: 36px;
                        line-height: 36px;
                        background: #39BC9C;
                        border-radius: 2px;
                        color: #fff;
                        text-align: center;
                        cursor: pointer;
                        bottom: 20px;
                        right: 10px;
                        font-size: 16px;

                        &:hover{
                            background-color: #36B495;
                        }
                    }
                    .time-info{
                        margin-top: 20px;
                        position: relative;
                        padding-top: 6px;
                        &:before{
                            display: block;
                            position: absolute;
                            content: '';
                            width: 42px;
                            height: 1px;
                            background: #ddd;
                            left: 0;
                            top: 0;
                        }
                    }
                    .list-info{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space:nowrap;
                        .info-item{
                            display: inline-block;
                            padding: 0 6px;
                            border-right: 1px solid #666;
                            line-height: 1;
                            &:last-child{
                                border: none;
                            }
                            &:first-child{
                                padding-left: 0;
                            }
                        }
                    }

                    .el-button {
                        margin-left: 11px;
                        text-align: center;
                        min-width: 100px;
                        border: none;
                        background: #ebf8f5 !important;

                        &:focus{
                            color: $primary;
                            background: #ebf8f5 !important;
                        }

                        &:hover{
                            color: #fff;
                            background-color: $primary !important;
                        }
                    }
                }
            }
        }
        .result-wrap{
            position: relative;
            text-align: center;
            padding: 50px 0;
            .result-text{
                color: #444;
                font-size: 24px;
                vertical-align: middle;
                color: #444;
                .icon-new{
                    font-size: 50px;
                    color: #38BC9D;
                    margin-right: 20px;
                }
                .success-text {
                    margin: 30px 0 0;
                    font-size: 24px;
                    color: #444444;
                }
            }
            .opr-btns{
                margin-top: 50px;
                display: inline-block;
                // margin-left: 20px;
                vertical-align: middle;
                .btn-new{
                    display: inline-block;
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    margin: 0 10px;
                    font-size: 14px;
                    border-radius: 2px;

                    &.look-btn{
                        background: #38BC9D;
                        color: #fff;
                        border-radius: 2px;
                    }
                    &.back-btn{
                        background: #F3F3F3;
                        color: #666;
                        border-radius: 2px;
                    }
                    &.recommend-btn{
                        // width: 220px;
                        // height: 48px;
                        // line-height: 48px;
                        width: 120px;
                        height: 36px;
                        line-height: 36px;
                        color: #fff;
                        background: #38BC9D;
                        // font-size: 24px;
                        font-size: 14px;
                    }
                }
            }
            .qrcode-wrap{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                text-align: center;
                cursor: pointer;
                z-index: 6;
                &:hover{
                    .qrcode-img{
                        padding: 10px;
                        display: block;
                    }
                }
                .icon-qrcode{
                    font-size: 36px;
                    color: #38BC9D;
                }
                >p{
                    font-size: 12px;
                    color: #999;
                    margin-top: -10px;
                }
                .qrcode-img{
                    display: none;
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    background: #fff;
                    top: 46px;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: 0 0 10px rgba(0,0,0,.2);
                    >img{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .el-input__inner{
            border: none !important;
        }
        .el-select > .el-input{
            margin-top: 2px;
        }
    }


</style>

